import { Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { Severty, ShowToast } from "../../../helper/toast";
import useRequest from "../../../hooks/useRequest";
import UploadImage from "./_UploadImage";
import { useAppContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";
import apiPath from "../../../constants/apiPath";

const AddForm = ({ section, api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState({
    mobile_number: "",
    country_code: "",
  });

  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);

  const { country: mCountry, cities } = useAppContext();

  const handleChange = (value, data) => {
    var country_code = data.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data.dialCode.length),
    });
  };

  const getCountry = () => {
    request({
      url: apiPath.common.country,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (status) {
          setCountry(data);
        }
      },
    });
  };

  const getCity = (id) => {
    request({
      url: apiPath.common.city + id,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "city");
        if (status) {
          setCity(data);
        }
      },
    });
  };

  // useEffect(() => {
  //   getCountry();
  // }, []);

  useEffect(() => {
    if (!data) return;

    form.setFieldsValue({
      ...data,
      dob: data.dob ? moment(data.dob) : "",
      // country_id: data.country_id?._id,
      // city_id: data.city_id?._id,
      mobile:
        data?.country_code && data?.mobile_number
          ? data?.country_code + data?.mobile_number
          : "",
    });
    // getCity(data.country_id?._id);

    setImage(data.image);
    setMobileNumber({
      mobile_number: data?.mobile_number,
      country_code: data?.country_code,
    });

    // console.log("data.country_id", data.country_id);
  }, [data]);

  useEffect(() => {
    if (!mCountry.country_id) return;

    // form.setFieldsValue({
    //   country_id: mCountry.country_id,
    // });

    // getCity(mCountry.country_id);
  }, [mCountry.country_id]);

  const onCreate = (values) => {
    const {
      name,
      ar_name,
      email,
      dob,
      country,
      city,
      area,
      gender,
      country_id,
      city_id,
    } = values;
    const payload = {};
    setLoading(true);
    payload.name = name;
    payload.dob = dob ? moment(dob).format("YYYY-MM-DD") : null;
    payload.country = country;
    payload.city = city;
    // payload.area = area;
    payload.gender = gender;
    payload.mobile_number = mobileNumber.mobile_number;
    payload.country_code = mobileNumber.country_code;
    payload.ar_name = ar_name;
    payload.email = email;
    // payload.country_id = mCountry.country_id;
    // payload.city_id = city_id;
    payload.image = image;
    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      maskClosable={false}
      open={show}
      width={750}
      okText={data ? lang("Update") : lang("Add")}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
      cancelText={lang("Cancel")}
    >
      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        
        layout="vertical"
        initialValues={{
          dob: moment("01-01-1990", "DD-MM-YYYY"),
        }}
      >
        <div className="add_user_title">
          <h4 className="modal_title_cls">{`${
            data
              ? lang("Edit User") /* sectionName */
              : lang("Add User") /* sectionName */
          }`}</h4>
        </div>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <div className="text-center">
              <Form.Item
                className="upload_wrap"
                rules={[
                  {
                    validator: (_, value) => {
                      if (image) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(lang("Profile image is required"))
                      );
                    },
                  },
                ]}
                name="image"
              >
                <UploadImage
                  preview={false}
                  value={image}
                  setImage={setImage}
                />
              </Form.Item>
            </div>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={<span>{lang(`Name`)}</span>}
              name="name"
              rules={[
                { required: true, message: lang("Please enter the to name") },
                {
                  max: 200,
                  message: lang(
                    "Name should not contain more then 200 characters!"
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={lang(`Enter Name`)} />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item
              label={<span>{lang(`Email ID`)}</span>} 
              name="email"
              rules={[
                {
                  type: "email",
                  message: lang("The email is not a valid email!"),
                },
                { required: true, message: lang("Please enter the email!") },
                {
                  max: 200,
                  message: lang(
                    "Email should not contain more then 200 characters!"
                  ),
                },
                {
                  min: 5,
                  message: lang("Email should contain at least 5 characters!"),
                },
                {
                  pattern: new RegExp(
                    /^([a-zA-Z0-9._%-]*[a-zA-Z]+[a-zA-Z0-9._%-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
                  ),
                  message: lang("Enter valid email!"),
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder={lang("Enter Email Address")}
              />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12} className="flagMobileNumber">
            <Form.Item
              label={<span>{lang(`Mobile Number`)}</span>}
              name="mobile"
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    if (!value) {
                      return Promise.reject(lang("Please enter phone number"));
                    }
                    if (!/^\d{8,12}$/.test(mobileNumber?.mobile_number)) {
                      return Promise.reject(
                        lang("Phone number must be between 8 and 12 digits")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                inputProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: false,
                  autoFormat: false,
                  autoComplete: "off",
                }}
                isValid={(value, country) => {
                  if (value.match(/1234/)) {
                    return "Invalid value: " + value + ", " + country.name;
                  } else if (value.match(/1234/)) {
                    return "Invalid value: " + value + ", " + country.name;
                  } else {
                    return true;
                  }
                }}
                country={"jo"}
                preferredCountries={["jo"]}
                onChange={handleChange}
              />
            </Form.Item>

          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={<span>{lang(`Date of Birth`)}</span>}
              name="dob"
              rules={[
                {
                  required: true,
                  message: lang("Please select the date of birth"),
                },
              ]}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                placeholder={lang("Select Date Of Birth")}
                disabledDate={(current) =>
                  current && current > moment("01-01-2010").endOf("day")
                }
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={12}>
            <Form.Item
              label={<span>{lang(`Gender`)}</span>}
              name="gender"
              rules={[
                { required: true, message: lang("Please select the gender!") },
              ]}
            >
              <Select
                placeholder={lang("Select Gender")}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                <Select.Option value="M">{lang("Male")} </Select.Option>
                <Select.Option value="F">{lang("Female")} </Select.Option>
                {/* <Select.Option value="O">Other </Select.Option> */}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
