import { message, Modal, Upload as UploadAntd } from "antd";
import React, { useState, useEffect } from "react";
import { PlusOutlined } from '@ant-design/icons';
import { uploadFile } from 'react-s3';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';

const s3Config = {
    region: 'us-east-1',
    credentials: {
      accessKeyId: "IJTXH751MBXUWMTWM0SF",
      secretAccessKey: "lfTPRJ2PqAxsp3poTW9YlHktgaL1cFkLup8LidW9",
    },
    endpoint: "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/",
    forcePathStyle: true,
  };


const s3Client = new S3Client(s3Config);

const MultipleImageUpload = ({ data, fileType, imageType, btnName, onDelete, onChange ,maxCount= 8 }) => {

    const [fileList, setFileList] = useState([]);

    const beforeUpload = (file) => {
        if (fileType.includes(file.type)) {

        } else {
            message.error("File format is not correct")
            return false
        }
        const isLt2M = file.size / 1024 / 1024 < 5;

        if (!isLt2M) {
            message.error(`Image must be smaller than 5 MB!`)
            return false
        }
        return true
    }

    useEffect(() => {
        if (!data) return;
        const multipleFileList = data.map((url, index) => ({
            uid: `${index + 1}`,
            name: url,
            status: 'done',
            url: url,
        }));
        setFileList(multipleFileList)
    }, [data])


    const handleImgChangeOld = async (event) => {
        const { file } = event;
        uploadFile(file, s3Config(imageType)).then(data => {
            const fileData = {
                uid: file.uid,
                name: file.name,
                status: 'done',
                url: data.location,
                thumbUrl: data.location,
            }
            setFileList([...fileList, fileData]);
            if (onChange) {
                onChange([...fileList, fileData]);
            }
        }).catch(err => console.error(err));
    };


    const uploadFileToS3 = async (file, bucketName) => {
        const key = "plaint/" + imageType+ "/" + file.name
        const params = {
          Bucket: bucketName,
          Key: key,
          Body: file,
        };
    
        const upload = new Upload({
          client: s3Client,
          params: params,
        });
    
        try {
          const data = await upload.done();
          console.log(data)
          return data;
        } catch (err) {
          throw err;
        }
      };
    
      const handleImgChange = async (event) => {
    
        const { file } = event;
    
        // setLoading(true);
        try {
          const data = await uploadFileToS3(file, 'invent-colab-obj-bucket');
          const fileData = {
            uid: file.uid,
            name: file.name,
            status: 'done',
            url: data.Location,
            thumbUrl: data.Location,
          };
          setFileList([...fileList, fileData]);
          if (onChange) {
              onChange([...fileList, fileData]);
          }
    
    
          //   setFile([fileData]);
          console.log(data);
        } catch (err) {
          console.error(err);
        }
      };
    

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const uploadButton = (
        <div> <PlusOutlined /> <div style={{ marginTop: 8 }}>Upload {btnName}</div> </div>
    );

    const handleRemove = (file) => {
        console.log(fileList, file);
        const newFile = fileList.filter(item => item.uid != file.uid)
        setFileList(newFile)
        if (onChange) {
            onChange([...newFile]);
        }
        // onDelete(file.url)
    };

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
  
    const handleCancel = () => setPreviewVisible(false);
  
    const handlePreview = async (file) => {
      setPreviewImage(file.url || file.thumbUrl);
      setPreviewVisible(true);
    };

    return (
      <>
        <UploadAntd
            listType="picture-card"
            onRemove={handleRemove}
            onPreview={handlePreview}
            maxCount={maxCount}
            beforeUpload={beforeUpload}
            fileList={fileList}
            //onChange={handleChange}
            customRequest={handleImgChange}
        >
            {fileList.length >= maxCount ? null : uploadButton}
        </UploadAntd>
          <Modal
          bodyStyle={{ padding: "0px !important", width: "auto !important", background: "black" }}
          // style={{ padding: "0px !important", background: "black" }}
          open={previewVisible}
          title={''}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ minWidth: "100%" }} src={previewImage} />
        </Modal>
        </>
    );
};

export default MultipleImageUpload;