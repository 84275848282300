import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Form,
  Tag,
  Image,
  Tooltip,
  Select,
  DatePicker,
  Input,
} from "antd";
import apiPath from "../../constants/apiPath";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import ShowTotal from "../../components/ShowTotal";
import ConfirmationBox from "../../components/ConfirmationBox";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import EditIcon from "../../assets/images/edit.svg";
import { bannerType } from "../../constants/conts";
import moment from "moment";
import lang from "../../helper/langHelper";
const { RangePicker } = DatePicker;

function Index({data,loading}) {
  const sectionName = "Banner";


  const [list, setList] = useState(data);
  const [pagination, setPagination] = useState(5);


  useEffect(()=>{
    if(!data) return;
    setList(data)
    setPagination(data?.length)
    console.log("data ::", data)
  },[data])

  const columns = [
  
    {
      title: lang("Name"),
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => {
        return name ? <span className="cap">{name}</span> : "-";
      },
    },
    {
      title: lang("Phone Number"),
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (_, { mobile_number, country_code }) => {
        return mobile_number && country_code
          ? `(${country_code}) ${mobile_number}`
          : "-";
      },
    },
    {
      title: lang("Email"),
      dataIndex: "email",
      key: "email",
      render: (_, { email }) => {
        return email ? email : "-";
      },
    },
    {
      title: lang("Total Reports"),
      dataIndex: "report_count",
      key: "report_count",
      render: (_, { report_count }) => {
        return report_count ? report_count : "0";
      },
    },

  
  ];




  return (
    <>
      <div className="tabled bannerMain">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{
                    defaultPageSize: 10,
                    responsive: true,
                    total: pagination,
                    // showSizeChanger: true,
                    // pageSizeOptions: ["10", "20", "30", "50"],
                  }}
                  // onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
     
    </>
  );
}



export default Index;
