export const PATH_HEADING_MAP = {
  subadmin: {
    path: "/sub-admin",
    heading: "Sub Admin",
  },
  dashboard: {
    path: "/",
    heading: "Dashboard",
  },
};
