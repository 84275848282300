import { Button, Image, Input, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import EditIcon from "../../assets/images/edit.svg";
import ConfirmationBox from "../../components/ConfirmationBox";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import Plus from "../../assets/images/plus.svg";
import notfound from "../../assets/images/not_found.png";
import moment from "moment";
import { DownloadExcel } from "../../components/ExcelFile";
const { fromString } = require('html-to-text');

function Testimonial() {
  const routeName = "testimonial";
  const { setPageHeading, country } = useAppContext();
  const heading = lang("Testimonial Management");

  const api = {
    status: apiPath.listTestimonial,
    list: apiPath.listTestimonial,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();
  const [exportLoading, setExportLoading] = useState(false);


  const view = (id) => {
    navigate(`/${routeName}/view/${id}`);
  };

  const columns = [
    {
      title: lang("Image"),
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => (
        <Image
          width={50}
          src={image ? image : notfound}
          className="table-img"
        />
      ),
    },
    {
      title: lang("Client's Name"),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: lang("Arabic Name"),
      dataIndex: "ar_name",
      key: "ar_name",
    },
    {
      title: lang("Created On"),
      key: "created_at",
      dataIndex: "created_at",
      sorter: (a, b) => a?.created_at?.localeCompare(b?.created_at),
      sortDirections: ["ascend", "descend"],
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            <Tooltip
              className="edit-cls btnStyle primary_btn"
              title={lang("Edit")}
              color={"purple"}
              key={"updateemail"}
            >
              <Button
                onClick={() => navigate(`/${routeName}/${record._id}/edit`)}
              >
                <img src={EditIcon} />
              </Button>
            </Tooltip>
            {/* <Tooltip
              className="btnStyle btnOutlineDelete"
              title={"View"}
              color={"purple"} key={"viewemail"}>
              <Button onClick={() => { view(record._id) }}>
                view
              </Button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setPageHeading(heading);
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
  }, [refresh, debouncedSearchText]);

  const fetchData = (pagination, filters, sorter) => {
    const filterActive = filters ? filters.is_active : null;

    request({
      url:
        api.list +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${
          pagination.pagination ? pagination.current : 1
        }&limit=${
          pagination.pageSize ? pagination.pageSize : 10
        }&search=${debouncedSearchText}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data.docs);
        setPagination((prev) => ({
          current: pagination.current,
          total: data.data.totalDocs,
        }));
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const handleChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    let query = undefined;
    if (field && order) {
      query = `${field}=${order}`;
      console.log(query);
    }
    fetchData(pagination, filters, query);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1 });
  };

  const getExportData = async () => {
    try {
   
      setExportLoading(true);
      request({
        url:
          api.list +
          `?page=${
            pagination ? pagination.current : 1
          }&limit=${
            pagination ? pagination.total : 10000
          }&search=${debouncedSearchText}`,
        method: "GET",
        onSuccess: ({ data, status, total, message }) => {
          setExportLoading(false);
          if (status) {
            excelData(data.docs ?? []);
          }
        },
        onError: (error) => {
          console.log(error);
          setExportLoading(false);
          ShowToast(error, Severty.ERROR);
        },
      });
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };

  const excelData = (exportData) => {
    if (!exportData.length) return;

    const data = exportData.map((row) => {
      const stripHtmlTags = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");
      const descriptionText = row?.description ? stripHtmlTags(row.description) : "-";
      const arDescriptionText = row?.ar_description ? stripHtmlTags(row.ar_description) : "-";
      return {
      "Client Name" : row?.name || "-",
      "Arabic Name": row?.ar_name || "-",
      "Description" : descriptionText,
      "Arabic Description" : arDescriptionText,
      "Image": row?.image || "-",
      "Registered On": moment(row.created_at).format("DD-MM-YYYY"),
    }});
    DownloadExcel(data, "testimonial");
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang("Testimonial List")}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              <div className="pageHeadingSearch">
                <Input.Search
                  className="searchInput"
                  placeholder={lang("Search by  Name")}
                  onChange={onSearch}
                  allowClear
                />
              </div>
              <Button
                  loading={exportLoading}
                  onClick={() => getExportData()}
                  className="primary_btn btnStyle"
                >
                  {lang("Export to Excel")}
                </Button>
              <Button
                className="primary_btn btnStyle"
                onClick={() => navigate(`/testimonial/add`)}
              >
                <span className="add-Ic">
                  <img src={Plus} />
                </span>
                {lang("Add New Testimonial")}
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination withOutSearilNo">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              current: pagination?.current,    
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>
    </>
  );
}

export default Testimonial;
