import { Checkbox, Col, Form, Input, Modal, Radio, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { rolesOptions } from "../../../constants/var";
import { Severty, ShowToast } from "../../../helper/toast";
import useRequest from "../../../hooks/useRequest";
import { useAppContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";
import apiPath from "../../../constants/apiPath";

const AddForm = ({ setEmail, api, show, hide, selected, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [isCollector, setIsCollector] = useState(
    selected ? selected.is_collector : false
  );
  const [country, setCountry] = useState([]);
  const [selectedBox, setSelectedBox] = useState([]);
  const [city, setCity] = useState([]);
  const [mobileNumber, setMobileNumber] = useState({
    mobile_number: "",
    country_code: "",
  });

  const { cities, country: headerCountry } = useAppContext();
  const [role, setRole] = useState([]);

  const getCountry = () => {
    request({
      url: apiPath.common.country,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (status) {
          setCountry(data);
        }
      },
    });
  };

  const getrole = () => {
    request({
      url: apiPath.allRole,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setRole(data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleChange = (value, data) => {
    var country_code = data.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data.dialCode.length),
    });
  };

  const getCity = (id) => {
    request({
      url: apiPath.common.city + id,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "city");
        if (status) {
          setCity(data);
        }
      },
    });
  };

  const onCreate = (values) => {
    const { permission } = values;
    const payload = {
      ...values,
      is_collector: isCollector,
      country_id: headerCountry.country_id,
    };

    payload.mobile_number = mobileNumber.mobile_number;
    payload.country_code = mobileNumber.country_code;

    if (!permission) {
      payload.permission = [];
    }

    console.log(payload, "payload");
    //return
    setLoading(true);

    request({
      url: `${selected ? api.subAdmin + "/" + selected._id : api.subAdmin}`,
      method: selected ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);

          if (selected) {
            refresh();
          } else {
            setEmail(payload.email);
          }
          hide();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getCountry();
    getrole();
  }, []);

  useEffect(() => {
    if (!selected) return;

    form.setFieldsValue({
      ...selected,
      country_id: selected.country_id?._id,
      role_id: selected?.role_id?._id,
      password: "",
      mobile: selected.country_code
        ? selected.country_code + selected.mobile_number
        : "",
    });
    let box = [];

    if (selected.is_collector) {
      box.push("collector");
    }
    if (selected.permission.length) {
      box.push("role");
    }
    setSelectedBox(box);
    setIsCollector(selected.is_collector);
    getCity(selected.country_id?._id);
    setMobileNumber({
      mobile_number: selected.mobile_number,
      country_code: selected.country_code,
    });
  }, [selected]);

  const handleRoleChange = (value) => {
    console.log("value", value);
    const selectedRole = role.find((role) => role._id === value);
    if (selectedRole) {
      form.setFieldsValue({
        permission: selectedRole.permission,
      });
    }
  };

  return (
    <Modal
      width={780}
      maskClosable={false}
      open={show}
      okText={selected ? lang("Update") : lang("Create")}
      onCancel={hide}
      cancelText={lang("Cancel")}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      
      className="tab_modal"
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <h4 className="modal_title_cls">
          {selected ? lang("Edit") : lang("Add")} {lang("Sub-Admin")}
        </h4>
        <Row gutter={[16, 0]} className="w-100">
          <Col span={24} sm={24}>
            <Form.Item
              label={<span>{lang("Name")}</span>}
              name="name"
              rules={[
                {
                  required: true,
                  message: lang("Please enter name"),
                },
                {
                  max: 200,
                  message: lang(
                    "Name should not contain more then 200 characters!"
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={lang(`Enter Name`)} />
            </Form.Item>
          </Col>

          <Col span={24} sm={24}>
            <Form.Item
              label={<span>{lang("Role Title")}</span>}
              name="role_id"
            
              rules={[
                {
                  required: true,
                  message: lang("Please select the role!"),
                },
              ]}
            >
              <Select
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder={lang("Select role")}
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={handleRoleChange}
                autoComplete="off" 
              >
                {role?.map((item) => (
                  <Select.Option
                    key={item._id}
                    label={item.name}
                    value={item._id}
                  >
                    {item.name}{" "}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} lg={24} sm={24} className="flagMobileNumber">
            <Form.Item
              label={<span>{lang("Mobile Number")}</span>}
              name="mobile"
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    if (!value) {
                      return Promise.reject(lang("Please enter phone number"));
                    }
                    if (!/^\d{8,12}$/.test(mobileNumber?.mobile_number)) {
                      return Promise.reject(
                        lang("Phone number must be between 8 and 12 digits")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <PhoneInput
                inputProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: false,
                  autoFormat: false,
                  autoComplete: "off",
                }}
                isValid={(value, country) => {
                  if (value.match(/1234/)) {
                    return "Invalid value: " + value + ", " + country.name;
                  } else if (value.match(/1234/)) {
                    return "Invalid value: " + value + ", " + country.name;
                  } else {
                    return true;
                  }
                }}
                country={"jo"}
                preferredCountries={["jo"]}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col span={24} sm={24}>
            <Form.Item
              label={<span>{lang("Email ID")}</span>}
              name="email"
              rules={[
                { required: true, message: lang("Please enter the email!") },
                {
                  max: 200,
                  message: lang(
                    "Email should not contain more then 200 characters!"
                  ),
                },
                {
                  pattern: new RegExp(
                    /^([a-zA-Z0-9._%-]*[a-zA-Z]+[a-zA-Z0-9._%-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
                  ),
                  message: lang("Enter valid email!"),
                },
              ]}
            >
              <Input
                autoComplete="off"
                placeholder={lang("Enter Email Address")}
              />
            </Form.Item>
          </Col>
        
            <Col span={24} sm={12}>
              <Form.Item
                label={lang("Update Password")}
                name="password"
                rules={[
                  {
                    pattern: new RegExp(
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
                    ),
                    message: lang(
                      "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character"
                    ),
                  },
                  {
                    required:  selected ? false : true,
                    message: lang("Please enter your password!"),
                  },
                ]}
              >
                <Input.Password
                  onCut={(e) => e.preventDefault()}
                  onCopy={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="off"
                  placeholder={lang("Create Password")}
                />
              </Form.Item>
            </Col>
         

          
            <Col span={24} sm={12}>
              <Form.Item
                label={lang("Confirm Password")}
                name="confirm_password"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("password") || value) {
                        // Make confirm password required if password field is filled
                        if (!value) {
                          return Promise.reject(
                            new Error(lang("Please confirm your password!"))
                          );
                        } else if (getFieldValue("password") !== value) {
                          return Promise.reject(
                            new Error(lang("Passwords do not match!"))
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password  autoComplete="off"  placeholder={lang("Enter Confirm Password")} />
              </Form.Item>
            </Col>
     
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
