import { Button, Image, Input, Switch, Table, Tooltip, Select } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UndoOutlined } from "@ant-design/icons";
import notfound from "../../assets/images/not_found.png";

import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";

import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";

import EditIcon from "../../assets/images/edit.svg";
import { DownloadExcel } from "../../components/ExcelFile";
import { kMaxLength } from "buffer";

function Index() {
  const heading = lang("Report") + " " + lang("Management");
  const { setPageHeading, country } = useContext(AppStateContext);



  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate()
  
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [exportLoading, setExportLoading] = useState(false);


  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    start_date: undefined,
    end_date: undefined,
    status: undefined,
  });

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const columns = [
    // {
    //   title: lang("S. No"),
    //   dataIndex: "index",
    //   key: "index",
    //   render: (value, item, index) =>
    //     pagination.current === 1
    //       ? index + 1
    //       : (pagination.current - 1) * 10 + (index + 1),
    // },
    {
      title: lang("Customer Image"),
      dataIndex: "image",
      key: "image",
      render: (_, { user_id }) => (
        <Image
          width={50}
          src={user_id.image ? user_id.image : notfound}
          className="table-img"
        />
      ),
    },
    {
      title: lang("Customer Name"),
      dataIndex: "name",
      key: "name",
      render: (_, { user_id }) => {
        return user_id?.name ? <span className="cap">{user_id?.name}</span> : "-";
      },
    },
    {
      title: lang("Provider Name"),
      dataIndex: "vendor_id",
      key: "vendor_id",
      render: (_, { vendor_id }) => {
        return vendor_id?.name ? <span className="cap">{vendor_id?.name}</span> : "-";
      },
    },
  
    {
      title: lang("reported On"),
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            <Tooltip
              title={lang("View Detail")}
              color={"purple"}
              key={"viewDetail"}
            >
              <Button className="ms-sm-2 mt-xs-2 btnStyle btnOutlineDelete" onClick={()=>navigate(`/reports/${record?._id}`)}>
                {lang("View Detail")}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
   
   
  ];

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
  }, [refresh, debouncedSearchText, country.country_id, filter]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;
    const queryString = Object.entries(filter)
      .filter(([_, v]) => v != undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    request({
      url:
        apiPath.reportList +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${
          pagination ? pagination.current : 1
        }&limit=${
          pagination ? pagination.pageSize : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setLoading(false);
        setList(() => {
          return data.docs.map((item) => {
            return {
              ...item,
              key: item._id,
            };
          });
        });
        setPagination((prev) => ({
          ...prev,
          current: pagination.current,
          total: data.totalDocs,
        }));
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);

        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };


  const getExportData = async () => {
    try {
   

      setExportLoading(true);
      request({
        url:
        apiPath.reportList +
          `?page=${pagination ? pagination.current : 1
          }&limit=${pagination ? pagination.total : 100000
          }`,
        method: "GET",
        onSuccess: ({ data, status, total, message }) => {
          setExportLoading(false);
          if (status) {
            excelData(data.docs ?? []);
          }
        },
        onError: (error) => {
          console.log(error);
          setExportLoading(false);
          ShowToast(error, Severty.ERROR);
        },
      });
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };

  const excelData = (exportData) => {
    console.log(exportData, "exportData")
    if (!exportData.length) return;

    const data = exportData.map((row) => ({
      "Provider Name": row?.vendor_id?.name ? row?.vendor_id?.name : "-",
      "Customer Name": row?.user_id?.name ? row?.user_id?.name : "-",
      "Comments" :  row?.comment ? row?.comment : "-",
      "Reported On": moment(row.created_at).format("DD-MM-YYYY"),
    }));
    DownloadExcel(data, "reports");
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang(`All Report's`)}
        extra={
          <div className="tab-upload-wrap d-flex align-items-center justify-content-between">
       
          <Button
                  loading={exportLoading}
                  onClick={() => getExportData()}
                  className="primary_btn btnStyle"
                >
                  {lang("Export to Excel")}
                </Button>
        </div>
        }
      >
        <div className="table-responsive customPagination withOutSearilNo">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>
    </>
  );
}

export default Index;
