import { Button, Table, Tooltip, Select, Modal, Input } from "antd";
import React, { useContext, useEffect, useState } from "react";

import Plus from "../../../assets/images/plus.svg";
import DeleteModal from "../../../components/DeleteModal";
import SectionWrapper from "../../../components/SectionWrapper";
import apiPath from "../../../constants/apiPath";
import { AppStateContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";
import { Severty, ShowToast } from "../../../helper/toast";
import useDebounce from "../../../hooks/useDebounce";
import useRequest from "../../../hooks/useRequest";
import AddForm from "./AddForm";
import { UndoOutlined } from "@ant-design/icons";
import { BlockSubAdmin, rolesOptions } from "../../../constants/var";
// import CheckIcon from "../../assets/images/check.svg"
import Lottie from "react-lottie";
import ViewLogs from "../../../components/ViewLogs";
import * as success from "../../../assets/animation/success.json";
import { DeleteSubadmin } from "../../../constants/reasons";
import moment from "moment";
import { DownloadExcel } from "../../../components/ExcelFile";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: success,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function Index() {
  const { setPageHeading, country } = useContext(AppStateContext);

  const sectionName = lang("Role");
  const routeName = "role";

  const api = {
    status: apiPath.statusRole,
    role: apiPath.role,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [logs, showLogs] = useState(false);
  const [selected, setSelected] = useState();
  const [email, setEmail] = useState();
  const [endDate, setEndDate] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [showMessage, setShowMessage] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });
  const [page, setPage] = useState(1);
  const [exportLoading, setExportLoading] = useState(false);


  const getFilter = () => {
    request({
      url: `${api.role}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const columns = [
    {
      title: lang("S. No"),
      dataIndex: "index",
      key: "index",
      render: (value, item, index) =>
        `${
          pagination.current === 1
            ? index + 1
            : (pagination.current - 1) * 10 + (index + 1)
        }`,
    },
    {
      title: lang("Title"),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      render: (_, { name }) => {
        return name;
      },
    },
    // {
    //   title: lang("Permission"),
    //   dataIndex: "permission",
    //   key: "permission",
    //   render: (_, { permission }) => {
    //     return permission ? permission.join(",  ") : "-";
    //   },
    // },
    {
      title: lang("Register Date"),
      key: "created_at",
      dataIndex: "created_at",
      sorter: (a, b) => a?.created_at.localeCompare(b?.created_at),
      sortDirections: ['ascend', 'descend'],
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            <>
              <Tooltip
                title={lang("Edit")}
                color={"purple"}
                key={"update" + routeName}
              >
                <Button
                  title="Edit"
                  className="edit-cl primary_btn"
                  onClick={() => {
                    setSelected(record);
                    setVisible(true);
                  }}
                >
                  <i class="fa fa-light fa-pen"></i>
                  <span>{lang("Edit")}</span>
                </Button>
              </Tooltip>

              <Tooltip
                title={lang("Delete")}
                color={"purple"}
                key={"delete" + routeName}
              >
                <Button
                  className="delete-cls"
                  title={lang("Delete")}
                  onClick={() => {
                    if (record.have_item) {
                      return setShowMessage(true);
                    }
                    setSelected(record);
                    setShowDelete(true);
                  }}
                >
                  <i class="fa fa-light fa-trash"></i>
                  <span>{lang("Delete")}</span>
                </Button>
              </Tooltip>
            </>
          </>
        );
      },
    },
  ];

  const onDelete = (id) => {
    request({
      url: api.role + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChangeStatus = (id, message) => {
    request({
      url: api.role + "/" + id + "/status",
      method: "PUT",
      data: { message },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
        ShowToast(data.message, Severty.SUCCESS);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchData = (pagination, filters, sorter) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        api.role +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${
          pagination ? pagination.current : 1
        }&pageSize=${
          pagination && pagination.pageSize ? pagination.pageSize : 10
        }${sorter ? `&${sorter}` : ""}&search=${debouncedSearchText}${
          queryString ? `&${queryString}` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);

        setList(data);
        console.log(data, "data11");
        setPagination((prev) => ({
          ...prev,
          current: pagination.current,
          total: total,
        }));
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    let query = undefined;
    if (field && order) {
      query = `${field}=${order}`;
      console.log(query);
    }
    fetchData(pagination, filters, query);
  };

  useEffect(() => {
    setLoading(true);
    fetchData({...pagination , current: 1 });
    // getFilter();
  }, [refresh, debouncedSearchText, filter, country?.country_id]);

  useEffect(() => {
    setPageHeading(`${lang("Role")} ${lang("Management")}`);
  }, []);

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };


  const getExportData = async () => {
    try {
      const queryString = Object.entries(filter)
        .filter(([_, v]) => v)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");
      setExportLoading(true);
      request({
        url:
          api.role +
          `?page=${pagination ? pagination?.current : 1}&pageSize=${
            pagination?.total ?  pagination?.total  : 1000
          }&search=${debouncedSearchText}${
            queryString ? `&${queryString}` : ""
          }`,
        method: "GET",
        onSuccess: ({ data, status, total, message }) => {
          setExportLoading(false);
          if (status) {
            excelData(data ?? []);
          }
        },
        onError: (error) => {
          console.log(error);
          setExportLoading(false);
          ShowToast(error, Severty.ERROR);
        },
      });
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };

  const excelData = (exportData) => {
    if (!exportData.length) return;

    const data = exportData?.map((row) => ({
       Name: row?.name || "-",
       permissions : row?.permission?.length ? row?.permission?.join(" , ") : "",
      "Registered On": moment(row.created_at).format("DD-MM-YYYY"),
    }));
    DownloadExcel(data, sectionName);
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang("Role") + " " + lang("List")}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              <div 
              className="pageHeadingSearch pageHeadingSearchmain-yy"
              >
                <Input.Search
                  allowClear={true}
                  className="searchInput"
                  placeholder={lang("Search by role")}
                  onChange={onSearch}
                />
              </div>
            

              {/* <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder="Role"
                  value={filter.role}
                  filterOption={false}
                  options={rolesOptions.map((item) => ({
                    value: item.name,
                    label: item.label,
                  }))}
                  onChange={(value) => onChange("role", value)}
                />
              </div>

              <Button
                onClick={() =>
                  setFilter({
                    country_id: undefined,
                    city_id: undefined,
                    year: undefined,
                    month: undefined,
                  })
                }
                type="primary"
                icon={<UndoOutlined />}
              >
                {lang("Reset")}
              </Button> */}
               <Button
                    loading={exportLoading}
                    onClick={() => getExportData()}
                    className="ms-sm-2 mt-xs-2 primary_btn btnStyle"
                  >
                    {lang("Export to Excel")}
                  </Button>
              <Button
                className="primary_btn btnStyle"
                onClick={(e) => {
                  setVisible(true);
                  setSearchText("");
                }}
              >
                <span className="add-Ic">
                  <img src={Plus} />
                </span>
                {lang("Add")} {sectionName}
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              current: pagination?.current,    
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AddForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          selected={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {showDelete && (
        <DeleteModal
          title={lang("Delete  Role")}
          subtitle={lang(
            `Are you sure you want to Delete this sub admin role?`,
          )}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelected();
          }}
          onOk={() => onDelete(selected?._id)}
          reasons={DeleteSubadmin}
        />
      )}

      {showStatus && (
        <DeleteModal
          title={`${
            selected?.is_active ? lang(`Block`) : lang(`UnBlock`)
          } ${lang("Sub Admin Role")}`}
          subtitle={`${lang("Are you sure you want to")} ${
            selected?.is_active ? lang(`block`) : lang(`unblock`)
          } ${lang("this sub admin role?")}`}
          show={showStatus}
          hide={() => {
            setShowStatus(false);
            setSelected();
          }}
          reasons={selected?.is_active ? BlockSubAdmin : []}
          onOk={(message) => handleChangeStatus(selected?._id, message)}
        />
      )}

      {logs && (
        <ViewLogs
          data={selected}
          show={logs}
          hide={() => {
            showLogs(false);
            setSelected();
          }}
        />
      )}
      {showMessage && (
        <DeleteModal
          title={lang("Delete Role")}
          subtitle={lang(
            "This Role contains Sub-Admin, please remove  this sub admin from sub admin before deleting Role",
          )}
          show={showMessage}
          hide={() => {
            setShowMessage(false);
          }}
          onOk={() => setShowMessage(false)}
          reasons={[]}
        />
      )}

      {showLink && (
        <Modal
          width={750}
      maskClosable={false}
      open={showLink}
          onOk={() => {}}
          onCancel={() => setShowLink(false)}
          centered
          className="tab_modal deleteWarningModal"
          footer={null}
        >
          <Lottie options={defaultOptions} height={120} width={120} />
          <h4 className="modal_title_cls mb-2 mt-3">{`Link Send Successfully`}</h4>
          <p className="modal_link_inner mb-0 mt-3">
            Link sent to your email address{" "}
            <span>
              {email?.slice(0, 4) + "XXXX" + email?.slice(email?.indexOf("@"))}.
            </span>
          </p>
          <p className="modal_link_inner">{`Please check email and set your password.`}</p>

          <div className="d-flex align-items-center gap-3 justify-content-center mt-5">
            <Button
              onClick={() => {
                setRefresh((prev) => !prev);
                setShowLink(false);
              }}
              className="primary_btn btnStyle "
            >
              Okay
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default Index;
