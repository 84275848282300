import { Checkbox, Col, Form, Input, Modal, Radio, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ProfileOptions } from "../../constants/var";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import apiPath from "../../constants/apiPath";

const AddForm = ({ api, show, hide, selected, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [selectedBox, setSelectedBox] = useState([]);
  const [city, setCity] = useState([]);
  const [category, setCategory] = useState([]);
  const { cities, country: headerCountry } = useAppContext();
  const [selectAll, setSelectAll] = useState(false);
  const [options, setOptions] = useState(ProfileOptions);
  const {language} = useAppContext() 

  const getCountry = () => {
    request({
      url: apiPath.common.country,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (status) {
          setCountry(data);
        }
      },
    });
  };

  const getCategory = () => {
    request({
      url: api.category,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setCategory(data);
        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const onCreate = (values) => {
    const payload = {
      ...values,
      country_id: headerCountry.country_id,
    };
    payload.permission = options;

    console.log(payload, "payload");
    setLoading(true);

    request({
      url: `${selected ? api.list + "/" + selected._id : api.list}`,
      method: selected ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          refresh();
          hide();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  
  };

  const onChange = (val) => {
    if (val) {
      const ctg = category.map((ctg) => ctg?._id)
      console.log(ctg, "  ::::: ctg");
      form.setFieldValue("category_id", [...ctg]);
    } else {
      const ctg = form.getFieldValue("category_id");
      form.setFieldValue("category_id", [...ctg]);
    }
  }

  useEffect(() => {
    getCountry();
    getCategory();
  }, []);

  useEffect(() => {
    console.log(options, "options");
  }, [options]);

  useEffect(() => {
    if (!selected) return;
    let ctgs = selected?.category_id?.map(ctg => ctg._id);
    form.setFieldsValue({
      ...selected,
      country_id: selected.country_id,
      category_id: ctgs,
    });

    // const uniqueByName = (arr1, arr2) => {
    //   const seen = new Set();
    //   const result = [];


    //   arr2.forEach((item) => {
    //     if (item.is_selected || item.is_required) {
    //       if (!seen.has(item.name)) {
    //         result.push(item);
    //         seen.add(item.name);
    //       }
    //     }
    //   });

    //   arr1.forEach((item) => {
    //     if (!seen.has(item.name)) {
    //       result.push(item);
    //       seen.add(item.name);
    //     }
    //   });

    //   return result;
    // };

    // setOptions(uniqueByName(options, selected.permission));

    const uniqueByName = (arr1, arr2) => {
      const seen = new Set();
      const result = [];
    
      // First, add items from arr1 that are both is_disable and is_required
      arr1.forEach((item) => {
        if (item.is_disable && item.is_required) {
          if (!seen.has(item.name)) {
            result.push(item);
            seen.add(item.name);
          }
        }
      });
    
      arr2.forEach((item) => {
        if (item.is_selected || item.is_required) {
          if (!seen.has(item.name)) {
            result.push(item);
            seen.add(item.name);
          }
        }
      });
    
      arr1.forEach((item) => {
        if (!seen.has(item.name)) {
          result.push(item);
          seen.add(item.name);
        }
      });
    
      return result;
    };
    
    setOptions(uniqueByName(options, selected.permission));
    

    console.log(options, "option12")
  }, [selected]);

  const handleCheckboxChange = (checked) => {
    setSelectAll(checked);
    const allCategoryIds = category.map((item) => item._id);
    form.setFieldValue('category_id', checked ? allCategoryIds : []);
  };


  return (
    <Modal
      maskClosable={false}
      width={780}
      open={show}
      okText={selected ? lang("Update") : lang("Create")}
      onCancel={hide}
      cancelText={lang("Cancel")}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      className="tab_modal"
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <h4 className="modal_title_cls">{selected ? lang("Edit") : lang("Add")} {lang("SP Profile")}</h4>
        <Row gutter={[16, 0]} className="w-100">
          <Col span={24} sm={24}>
            <Form.Item
              label={<span>{lang(`Name`)}</span>}
              name="name"
              rules={[
                {
                  max: 200,
                  message: lang(
                    "Name should not contain more then 200 characters!",
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
                {
                  required: true,
                  message: lang("Please enter name"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={lang(`Enter Name`)} />
            </Form.Item>
          </Col>

          <Col span={24} sm={24}>
            <Form.Item
              label={
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span>{lang("Category Name")}</span>
                  <Checkbox className="ms-2" value={selectAll} 
                  // onChange={({ target }) => { onChange(target.checked) }}
                  onChange={(e) => handleCheckboxChange(e.target.checked)}
                   />
                </div>}
              name="category_id"
              rules={[
                {
                  required: true,
                  message: lang("Please select the category!"),
                },
              ]}
            >
              <Select
                className="height-select"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                mode="multiple"
                placeholder={lang("Select Category")}
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                value={form.getFieldValue("category_id")}
                onChange={(val) => {
                  form.setFieldValue("category_id", val)
                }}
              >
                {category.map((item) => (
                  <Select.Option
                    key={item._id}
                    label={item.name}
                    value={item._id}
                  >
                   { language !== "en" && language !== null
                                ? item?.[`${language}_name`] ?? item?.name
                                : item?.name }
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24} md={24}>
            <div className="assign_role">
              <h6>{lang("Select Fields")}</h6>
              {
                <Form.Item
                  // name="permission"
                  className="profile_checkbox"
                  rules={[
                    {
                      required: false,
                      message: lang("Please assign min. 1 field"),
                    },
                  ]}
                >
                  <Row>
                    {options?.map((item, idx) => (
                      <Col span={24} md={12}>
                        <div className="fild-name" key={item.name}>
                          <Checkbox
                            disabled={item.is_disable}
                            checked={item.is_selected}
                            onChange={({ target }) => {
                              if (target.checked) {
                                const newArray = [...options];
                                newArray[idx] = {
                                  ...newArray[idx],
                                  is_selected: true,
                                };
                                setOptions(newArray);
                                //array idx se update with true is_selected = true
                              } else {
                                const newArray = [...options];
                                newArray[idx] = {
                                  ...newArray[idx],
                                  is_selected: false,
                                };
                                setOptions(newArray);
                                //array idx se update with true is_selected = false
                              }
                            }}
                          >
                            {item.label}
                          </Checkbox>

                          <Checkbox
                            disabled={item.is_disable}
                            checked={item.is_required}
                            value={item.name}
                            onChange={({ target }) => {
                              if (target.checked) {
                                const newArray = [...options];
                                newArray[idx] = {
                                  ...newArray[idx],
                                  is_required: true,
                                };
                                setOptions(newArray);
                                //array idx se update with true is_required = true
                              } else {
                                const newArray = [...options];
                                newArray[idx] = {
                                  ...newArray[idx],
                                  is_required: false,
                                };
                                setOptions(newArray);
                                //array idx se update with true is_required = false
                              }
                            }}
                          >
                            Required
                          </Checkbox>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Form.Item>
              }
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
