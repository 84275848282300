import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Modal,
  Tooltip,
  Form,
  Input,
  Tag,
  Descriptions
} from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router";
// import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the desired Quill stylesheet
import 'react-phone-input-2/lib/style.css'
import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import Main from "../../components/layout/Main";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import apiPath from "../../constants/apiPath";
import EditIcon from "../../assets/images/edit.svg";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";


function Invitation() {

  const heading = lang("Invitation") + " " + lang("Management");
  const { setPageHeading, country } = useContext(AppStateContext);

  const sectionName = "Invitation";
  const { request } = useRequest()
  const navigate = useNavigate()

  const [list, setList] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  const {confirm} = Modal
  
  const api = {
   
    view : apiPath.viewInvite,
    list:  apiPath.listInvite,
    addEdit : apiPath.listInvite,
  };



  const statusChange = (record,type) => {
    console.log(record, 155)
    const payload = {}
    console.log(type, 164)
    setLoading(true);
    request({
      url: `${apiPath.viewInvite}/${record}/${type}`,
      method: 'put',
      data: payload,
      onSuccess: (data) => {
        // Reload the window

        setRefresh(prev => !prev);
        setLoading(false)

      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    // {
    //   title: "STATUS",
    //   render: (_, { is_status, _id }) => {
    //     let color = is_status ? 'green' : 'red';
    //     return (
    //       <Tag color={color} key={is_status} onClick={(e) => showConfirm(_id,"is_active")} >
    //         {is_status ? "Active" : "Inactive"}
    //       </Tag>
    //     );
    //   },
    // },
    // {
    //   title: "Registartion date",
    //   key: "created_at",
    //   dataIndex: "created_at",
    //   render: (_, { created_at }) => {

    //     return (
    //       new Date(created_at).toLocaleString().slice(0, 9)
    //     );
    //   },

    // },
    {
      title: lang("Action"),
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <Tooltip
              title={lang("Edit")}
              color={"purple"}
              key={"update"}
            >
              <Button
                title="Edit"
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  setSelected(record)
                  setVisible(true)
                }}
              >
                <img src={EditIcon} />
                <span>{lang("Edit")}</span>
              </Button>
            </Tooltip>

            <Tooltip title={lang("View")} color={"purple"} key={"View"}>
              <Button
                title={lang("View")}
                
                className="new-button-of-view90"
                onClick={() => {
                  navigate(`/invitation-template-manager/view/${record._id}`)
                }}
              >
                {/* <img src={deleteWhiteIcon} /> */}
                <span>{lang("View")}</span>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    // {
    //   title: "Action", 
    //   render: (_, record) => {

    //     return (
    //       <>
    //       <Tooltip placement="top" title={"Edit"}>
    //         <Button className="tableBtn" onClick={() => {
    //           setSelected(record)
    //           setVisible(true)
    //         }}>
    //            <img src={EditIcon} />
    //             <span>{lang("Edit")}</span>
    //         </Button>
    //       </Tooltip>
    //       &nbsp;&nbsp;
          
    //       <Tooltip placement="top" title={"View"}>
    //         <Button className="tableBtn" onClick={() => {
    //         navigate(`${record._id}/view-email`)

    //         }}>
    //         <i class="fa fa-eye" aria-hidden="true"></i>
    //         </Button>
    //       </Tooltip>
    //       </>
    //     );

    //   },

    // },
  ];
  useEffect(() => {
    setLoading(true)
    request({
      url: api.list,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        // ShowToast(data.message, Severty.SUCCESS)
        setList(data.data)
      },
    })
  }, [refresh])
  
  return (
 <>
      <div className="tabled emailListing singleBtn">
        <Row gutter={[24, 0]}>
          <Col span={24}  xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="All Templates"
            >
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={true}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      {visible && <AddFrom show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} api={api} />}
   </>
  );

}
const AddFrom = ({ show, hide, data, refresh  , api}) => {

  const [form] = Form.useForm();
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)

  const editor = useRef(null);

  useEffect(() => {
    if (!data) return
    form.setFieldsValue({ ...data })


  }, [data])
  const onCreate = (values) => {
    const { title, subject, description } = values
    const payload = {}

    setLoading(true)
    payload.title = title
    payload.subject = subject
    payload.description = description
    request({
      url: `${data ? `${api.addEdit}/${data._id}` : `${api.addEdit}`}`,
      // url: '/admin/expact/add-expact',
      method: `${data ? 'PUT' : 'POST'}`,
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS)
          hide()
          refresh()
          // hide()
        } else {
          ShowToast(data.message, Severty.ERROR)

        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  };
  return (
    <Modal
      open={show}
      title={`${data ? 'Edit' : 'Create'}`}
      okText="Submit"
      cancelText="Cancel"
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
      }}
      cancelButtonProps={{
        className: 'custom-cancel-button',  // Add your custom class here
      }}
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">

        <Form.Item
          label="Title"
          name="title"
          rules={[
            { required: true, message: "Please input the title!" }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Subject"
          name="subject"
          rules={[
            { required: true, message: "Please input the subject!" }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
        <JoditEditor
			ref={editor}
			
			tabIndex={1} 
      style = {{height: "30vh"}}
		/>
            {/* <ReactQuill type="textarea" style = {{height: "30vh"}} /> */}
          </Form.Item>
      </Form>
    </Modal>

  )
}

export default Invitation;
