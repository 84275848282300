import { Checkbox, Col, Form, Input, Modal, Radio, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { rolesOptions } from "../../../constants/var";
import { Severty, ShowToast } from "../../../helper/toast";
import useRequest from "../../../hooks/useRequest";
import { useAppContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";
import apiPath from "../../../constants/apiPath";

const AddForm = ({ api, show, hide, selected, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
 
  const [country, setCountry] = useState([]);
  const [selectedBox, setSelectedBox] = useState([]);
  const [city, setCity] = useState([]);
  

  const { cities, country: headerCountry } = useAppContext();

  const getCountry = () => {
    request({
      url: apiPath.common.country,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (status) {
          setCountry(data);
        }
      },
    });
  };



  const getCity = (id) => {
    request({
      // url: `/city/${id}`,
      url: apiPath.common.city + id,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "city");
        if (status) {
          setCity(data);
        }
      },
    });
  };

  const onCreate = (values) => {
    const { permission } = values;

    const payload = {
      ...values,
      country_id: headerCountry.country_id,
    };

    if (!permission.length > 0) {
      return ShowToast("Min. 1 Assign Roles is Required", Severty.ERROR);
    }

    console.log(payload, "payload");

    setLoading(true);

    request({
      url: `${selected ? api.role + "/" + selected._id : api.role}`,
      method: selected ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          refresh();
          hide();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getCountry();
  }, []);


  useEffect(() => {
    if (!selected) return;

    form.setFieldsValue({
      ...selected,
      country_id: selected.country_id?._id,
    });

    let box = [];

   
    if (selected.permission.length == rolesOptions.length) {
      box.push("role");
    }

    setSelectedBox(box);
   
    getCity(selected.country_id?._id);
   
  }, [selected]);

  return (
    <Modal
      width={780}
      maskClosable={false}
      open={show}
      okText={selected ? lang("Update") : lang("Create")}
      onCancel={hide}
      cancelText={lang("Cancel")}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      className="tab_modal"
    >
      <Form id="create" form={form} onFinish={onCreate} layout="vertical">
        <h4 className="modal_title_cls">{selected ? lang("Edit") : lang("Add")} {lang("Role")}</h4>
        <Row gutter={[16, 0]} className="w-100">
          <Col span={24} sm={24}>
            <Form.Item
              label={lang("Role Title")}
              name="name"
              rules={[
                {
                  max: 200,
                  message: lang(
                    "Title should not contain more then 200 characters!",
                  ),
                },
                {
                  min: 2,
                  message: lang("Title should contain at least 2 characters!"),
                },
                {
                  required: true,
                  message: lang("Please enter role title"),
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input
                autoComplete="off"
                placeholder={lang("Enter Role Title")}
              />
            </Form.Item>
          </Col>

          <Checkbox.Group
            value={selectedBox}
            onChange={(values) => {
              console.log(values, "valuesvalues");
              setSelectedBox(values);
             
              if (values.includes("role")) {
                form.setFieldsValue({
                  permission: rolesOptions.map(({ name }) => name),
                });
              } else {
                form.setFieldsValue({
                  permission: [],
                });
              }
            }}
          >
            <Col span={24} md={24}>
              <div className="assign_role">
                <Checkbox value="role">
                  {lang("Assign Roles And Responsibility")}
                </Checkbox>
                {
                  <Form.Item
                    name="permission"
                    className="assign_role_checkbox"
                    rules={[
                      {
                        required: false,
                        message: lang("Please assign roles and responsibility"),
                      },
                    ]}
                  >
                    <Checkbox.Group
                      onChange={(value) => {
                        if (value.length != rolesOptions.length) {
                          const old = [...selectedBox];
                          let index = old.indexOf("role");
                          if (index !== -1) old.splice(index, 1);
                          setSelectedBox(old);
                        }
                      }}
                    >
                      {rolesOptions.map((item, idx) => (
                        <Checkbox value={item.name} key={item.name}>
                          {item.label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                }
              </div>
            </Col>
          </Checkbox.Group>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
