import { Button, Image, Modal, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import notfound from "../../assets/images/not_found.png";
import Plus from "../../assets/images/plus.svg";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import EditIcon from "../../assets/images/edit.svg";
import AddNotificationModal from "./_AddNotificationModal";
import lang from "../../helper/langHelper";
// import AdvertisementBannerForm from "./AdvertisementBannerForm";

const { confirm } = Modal;

const PushNotificationManager = ({ sectionName }) => {
  const [pagination, setPagination] = useState({
    current: 1,
    total: 10,
  });

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const [selectedAddBanner, setSelectedAddBanner] = useState();
  const [visible, setVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [ sendingType,SetSendingType] = useState("create")

  const { request } = useRequest();

  const handleChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    let query = undefined;
    if (field && order) {
      query = `${field}=${order}`;
      console.log(query);
    }
    fetchData(pagination, filters, query);
  };

  const onDelete = (record) => {
    request({
      url: apiPath.notification + "/" + record?._id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const fetchData = (sorter) => {
    request({
      url:
        `${apiPath.notification}/push` +
        `?status=${""}&page=${pagination ? pagination.current : 1}&pageSize=${
          pagination && pagination?.total ? pagination?.total : 10
        }&search=${""}`,
      method: "GET",
      onSuccess: (data) => {
        setList(data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const columns = [
    {
      title: lang("S. No"),
      dataIndex: "index",
      key: "index",
      render: (value, item, index) =>
        pagination.current === 1
          ? index + 1
          : (pagination.current - 1) * 10 + (index + 1),
    },
    {
      title: lang("Title"),
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
      sortDirections: ["ascend", "descend"],
      render: (_, { title }) => title,
    },
    {
      title: lang("Arabic Title"),
      dataIndex: "title",
      key: "title",
      render: (_, { ar_title }) => ar_title,
    },
    {
      title: lang("Notifications"),
      dataIndex: "message",
      key: "message",
      sorter: (a, b) => a?.message?.localeCompare(b?.message),
      sortDirections: ["ascend", "descend"],
      render: (_, { message }) => message,
    },

    {
      title: lang("Arabic Notifications"),
      dataIndex: "message",
      key: "message",
      render: (_, { ar_message }) => ar_message,
    },
    {
      title: lang("Target Audience"),
      dataIndex: "audience",
      key: "audience",
      sorter: (a, b) => a?.audience?.localeCompare(b?.audience),
      sortDirections: ["ascend", "descend"],
      render: (_, { audience }) => {
        return audience ? <span className="cap">{audience}</span> : "-";
      },
    },

    {
      title: lang("Action"),
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            {record?.status === "Draft" && (
              <>
                <Tooltip title={"Send"} color={"purple"} key={"update"}>
                  <Button
                    title="Edit"
                    className="btnStyle btnOutlineDelete"
                    onClick={() => {
                      // ShowToast('Notification')
                    }}
                  >
                    <span>Send</span>
                  </Button>
                </Tooltip>
                <Tooltip title={"Edit"} color={"purple"} key={"Edit"}>
                  <Button
                    title="Edit"
                    className="edit-cls btnStyle primary_btn"
                    onClick={() => {
                      setSelectedAddBanner(record);
                      setVisible(true);
                    }}
                  >
                    <img src={EditIcon} />
                    <span>Edit</span>
                  </Button>
                </Tooltip>
              </>
            )}
            <Tooltip title={lang("Resend")} color={"purple"} key={lang("Resend")}>
              <Button
                title={lang("Resend")}
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  setSelectedAddBanner(record);
                  setVisible(true);
                  SetSendingType("Resend")

                }}
              >
                {/* <img src={EditIcon} /> */}
                <span>{lang("Resend")}</span>
              </Button>
            </Tooltip>

            <Tooltip title={lang("Delete")} color={"purple"} key={lang("Delete")}>
              <Button
                title={lang("Delete")}
                className="btnStyle deleteDangerbtn"
                onClick={(e) => {
                  setSelectedAddBanner(record);
                  setShowDelete(true);
                }}
              >
                <img src={deleteWhiteIcon} />
                <span>{lang("Delete")}</span>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (_, { status }) => {
    //     return status ? status : "-";
    //   },
    // },
  ];

  useEffect(() => {
    fetchData(pagination);
  }, [refresh]);

  return (
    <>
      <SectionWrapper
        cardHeading={lang("Push Notifications")}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              <Button
                className="primary_btn btnStyle"
                onClick={(e) => {
                  setVisible(true);
                }}
              >
                <span className="add-Ic">
                  <img src={Plus} />
                </span>
                {lang("Add Notification")}
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AddNotificationModal
          type={sectionName}
          sectionName={sectionName}
          show={visible}
          hide={() => {
            setSelectedAddBanner();
            setVisible(false);
            SetSendingType()

          }}
          ant-btn-default
          data={selectedAddBanner}
          sendingType={sendingType}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {showDelete && (
        <DeleteModal
          title={lang("Delete Notification")}
          subtitle={lang(`Are you sure you want to Delete this notification?`)}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelectedAddBanner();
          }}
          onOk={() => onDelete(selectedAddBanner)}
        />
      )}
    </>
  );
};

export default PushNotificationManager;
