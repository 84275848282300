import React, { Suspense, useContext } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { AppContextProvider } from "./context/AppContext";
import { AuthContext, AuthProvider } from "./context/AuthContext";

import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/custom.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Loader from "./components/Loader";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Auth/Home";
import SignIn from "./pages/Auth/SignIn";
import Error from "./pages/Error";

import { PATH_HEADING_MAP } from "./constants/path-heading-map.constants";
import ViewAttribute from "./pages/Attribute/View";
import AddProvider from "./pages/Provider/AddProvider";
import ViewReports from "./pages/Reports/View"

import {
  Category,
  CmsManagement,
  Customer,
  DeliveryHistory,
  Discount,
  Notifications,
  Order,
  Ratings,
  Register,
  Reports,
  Provider,
  ViewProviderService,
  ViewProviderPackage,
  SubAdmin,
  SubCategory,
  EventType,
  Service,
  Role,
  QuotationRequest,
  QuoteTemplate,
  ProfileProvider,
  Blog,
  ViewProvider,
  Testimonial,
  TestimonialAdd,
  Invitation,
  ViewEmailTemplate
} from "./pages";

import DeliveryCharge from "./pages/DeliveryCharges/Index";
import Driver from "./pages/Attribute/Index";

import EmailEdit from "./pages/EmailTemplate/Edit";
import EmailTemplate from "./pages/EmailTemplate/Index";
import ServiceLocation from "./pages/ServiceLocation/Index";
import ServiceLocationView from "./pages/ServiceLocation/View";
import ServiceLocationAreaView from "./pages/ServiceLocation/ViewArea";
import Attribute from "./pages/Attribute/Index";
import AddBlog from "./pages/Blog/Edit"
import ViewRequested from "./pages/QuotationRequests.js/ViewQuote";

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <I18nextProvider i18n={i18n}>
          <Suspense fallback={<Loader />}>
            <BrowserRouter>
              <ScrollToTop />
              <ToastContainer closeOnClick={false} />
              <AppRoutes />
            </BrowserRouter>
          </Suspense>
        </I18nextProvider>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route path="/sign-up" element={<Register />} />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        {/* Auth Routes */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/dashboard" element={<Home />} />
        {/* <Route exact path="/profile" element={<Profile />} /> */}

        {/* Customer Routes */}
        <Route exact path="/customer" element={<Customer />} />

        {/* Specialist Routes */}
        <Route
          exact
          path={PATH_HEADING_MAP.subadmin.path}
          element={<SubAdmin />}
        />
        {/* category */}
        <Route exact path="/category" element={<Category />} />

        {/* Role Routes */}
        <Route exact path="/role" element={<Role />} />

        {/* sub category */}
        <Route exact path="/sub-category" element={<SubCategory />} />

        {/* Event Type */}
        <Route exact path="/event-type" element={<EventType />} />

        {/* services */}
        <Route exact path="/service" element={<Service />} />

         {/* invitation-template-manager */}
         <Route exact path="/invitation-template-manager" element={<Invitation />} />
         <Route exact path="/invitation-template-manager/view/:id" element={<ViewEmailTemplate />} />

        {/* Provider */}
        <Route exact path="/profile" element={<ProfileProvider />} />
        <Route exact path="/service-provider" element={<Provider />} />
        <Route exact path="/service-provider/add" element={<AddProvider />} />
        <Route exact path="/service-provider/view-service/:id" element={<ViewProviderService />} />
        <Route exact path="/service-provider/view-package/:id" element={<ViewProviderPackage />} />


        <Route
          exact
          path="/service-provider/add/:id"
          element={<ViewProvider />}
        />

        {/* cms manager */}
        <Route exact path="/cms" element={<CmsManagement />} />
        {/* quote Templete manager */}
        <Route exact path="/quote-templates" element={<QuoteTemplate />} />

        {/* delivery manager */}
        <Route exact path="/delivery" element={<DeliveryHistory />} />

        {/* view quote manager */}
        <Route exact path="/quotation-request/view/:id" element={<ViewRequested />} />

        {/* Attribute Manager  */}
        <Route exact path="/attributes" element={<Attribute />} />
        {/* view Attribute */}
        <Route
          exact
          path="/attributes/:category_id/:service_id"
          element={<ViewAttribute />}
        />

        {/* finance */}
        <Route exact path="/quotation-request" element={<QuotationRequest />} />

        {/* ratings */}
        <Route exact path="/ratings" element={<Ratings />} />

        {/* notification */}
        <Route exact path="/notification" element={<Notifications />} />

       {/* blog */}
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/blog/add" element={<AddBlog />} />
        <Route exact path="/blog/:id/edit" element={<AddBlog />} />

        {/* testimonial */}
        <Route exact path="/testimonial" element={<Testimonial />} />
        <Route exact path="/testimonial/add" element={<TestimonialAdd />} />
        <Route exact path="/testimonial/:id/edit" element={<TestimonialAdd />} />

        {/* reports */}
        <Route exact path="/reports" element={<Reports />} />
        <Route exact path="/reports/:id" element={<ViewReports />} />

        {/* discount */}
        <Route exact path="/discount" element={<Discount />} />

        <Route exact path="/locations" element={<ServiceLocation />} />
        <Route exact path="/locations/:id" element={<ServiceLocationView />} />
        <Route
          exact
          path="/locations/:id/area"
          element={<ServiceLocationAreaView />}
        />

        <Route exact path="/delivery-charge" element={<DeliveryCharge />} />

        <Route exact path="/email-templates" element={<EmailTemplate />} />
        <Route exact path="/email-templates/:id/edit" element={<EmailEdit />} />

        {/* order */}
        <Route exact path="/order" element={<Order />} />
      </Route>

      <Route path="*" element={<Error />} />
    </Routes>
  );
};

const Layout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default App;
