import { Button, Col, Image, Modal, Row, Divider, Steps } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import notfound from "../assets/images/not_found.png";
import apiPath from "../constants/apiPath";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import { capitalize } from "../helper/functions";
import lang from "../helper/langHelper";

export const ApproveStatus = {
  REJECT: "rejected",
  ACCEPT: "accepted",
  SUSPENDED: "suspended",
  PENDING: "pending",
};

const ViewLogs = ({ show, hide, data }) => {
  const { request } = useRequest();

  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);

  const getLogs = () => {
    setLoading(true);
    request({
      url: apiPath.subAdminChangeLog + "/" + data?._id,
      method: "GET",
      onSuccess: ({ data }) => {
        setLoading(false);
        setLogs(data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!data) return;
    getLogs();
  }, [data]);

  return (
    <Modal
      open={show}
      maskClosable={false}
      width={750}
      okText="User"
      onCancel={hide}
      footer={[
        <Button key="approve" type="primary" onClick={hide}>
          {lang("Okay")}
        </Button>,
      ]}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal driver-modal"
    >
      <div className="driver_img">
        <Image
          src={data?.image ? data?.image : notfound}
          className="table-img"
          style={{ width: "70px", height: "70px" }}
        />
      </div>
      <div className="new_driver_dtl">
        <Row gutter={24}>
          <Col span={24} >
            <div className="sub-headding-sec">
              <h6>
                {data?.type} {lang("Name")}:
              </h6>
              <p className="font-bold">{data.name}</p>
            </div>
          </Col>
          <Col span={24}  >
          <div className="sub-headding-sec">
          <h6>
            {lang("Phone Number")}:</h6>
            <p className="font-bold">
              {data?.country_code + " " + data?.mobile_number}
            </p>
            </div>
          </Col>
          <Col span={24} >
          <div className="sub-headding-sec">
            <h6>{lang("Email Address")}:</h6>
            <p className="font-bold">{data?.email}</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="driver-personal-dtl">
      <Divider />
      { (logs.length && logs.length > 0 ) ? <Steps
          progressDot
          current={0}
          className="driver-dtl-text"
          direction="vertical"
          items={logs.map((item) => ({
            title: `${capitalize(item.action)} on ${moment(
              item.created_at,
            ).format("ll")}`,
            description: item.message,
          }))}
        /> : <h4 className="no-issue">{lang("No Activity")}</h4> }
      </div>
    </Modal>
  );
};

export default ViewLogs;
